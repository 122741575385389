<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="getRegions()">
                            <option v-for="year in committedYears" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="month" class="form-label select-lable lable-text"> Month </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="month"
                            v-model="selectedMonth" @change="getRegions()">
                            <option v-for="eachMonth in monthsList" v-bind:key="eachMonth.monthId"
                                :value="eachMonth.monthId">
                                {{ eachMonth.monthName }}
                            </option>
                        </select>
                    </div>
                    <div style="padding-left: 59%">
                        <button class="download-view action-btn"
                            style="background: transparent; margin-right: 0px; margin-left: 1rem" type="button"
                            v-b-tooltip.hover title="Export to Excel" @click="exportToExcel">
                            <img alt="download" src="../../../assets/exlDownload.svg" />
                        </button>
                    </div>
                </div>
            </div>

            <!-- Excel Format -->
            <b-table-simple responsive outlined id="oeeregionreport" class="oee-regional-count-table scroll"
                style="display: none; flex-wrap: wrap">
                <div id="target-approval-accordion">
                    <div class="accordion" style="border-top: 0.5px solid #eae6e6" role="tablist"
                        v-if="regionWiseList.length">
                        <div v-for="(actionMain, actionMainIndex) in regionWiseList" :key="(actionMain, actionMainIndex)"
                            class="add-top-border">
                            <div v-if="actionMain.oeeDetail.length" :id="actionMain.regionId">
                                <div class="level-status target-card-lable principle-name-label"
                                    @click="checkToggle(collapsed_values[actionMain.regionCode], actionMain.regionCode)">
                                    <b-button :aria-controls="actionMain.regionCode" class="img-button"
                                        :aria-expanded="collapsed_values[actionMain.regionCode] ? 'true' : 'false'">
                                        <img alt="down" v-if="!collapsed_values[actionMain.regionCode]"
                                            src="@/assets/accordian_down_arrow.svg" />
                                        <img alt="up" v-else src="@/assets/accordian_up_arrow.svg" />
                                    </b-button>
                                    {{ actionMain.regionName }}
                                </div>
                                <b-collapse v-bind:id="actionMain.regionCode"
                                    v-model="collapsed_values[actionMain.regionCode]">
                                    <div style="display: flex; flex-wrap: wrap">
                                        <div class="oee-regional-count">
                                            <b-table-simple responsive outlined id="oee-regional-count"
                                                class="oee-regional-count-table scroll gn-table-b">
                                                <b-thead head-variant="light" class="sticky">
                                                    <b-tr>
                                                        <b-th v-for="header in machineDetailHeader"
                                                            :rowspan="header.children.length ? 1 : 2"
                                                            :colspan="header.children.length ? 2 : 1" :key="header.key"
                                                            style="width: 6rem" class="first-tr-th">
                                                            {{ header.label }}
                                                        </b-th>
                                                    </b-tr>
                                                    <b-tr style="border-bottom: 2px solid #dddddd !important">
                                                        <b-th v-for="(header, i) in allColsHeader" :key="header.key + i"
                                                            class="second-tr-th">
                                                            {{ header.label }}
                                                        </b-th>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody v-if="actionMain.oeeDetail && actionMain.oeeDetail.length !== 0">
                                                    <b-tr v-for="(rowData, index) in actionMain.oeeDetail" :key="index">
                                                        <b-td v-for="(innerChild, key) in rowData" :key="key + index"
                                                            class="oee-regional-count-label">
                                                            {{ innerChild }}
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                                <b-tbody v-if="actionMain.oeeDetail && actionMain.oeeDetail.length === 0">
                                                    <tr>
                                                        <td class="text-center" style="height: 23rem" rowspan="6"
                                                            :colspan="allColsHeader.length + machineDetailHeader.length">
                                                            <img alt="no data" src="@/assets/No_Data.svg" />
                                                            <div class="ed-date">No Data Found</div>
                                                        </td>
                                                    </tr>
                                                </b-tbody>
                                                <b-tfoot style="color: #234372; background: #f0f7ff"
                                                    v-for="(rowData, index) in actionMain.summaryDetail" :key="index">
                                                    <b-tr>
                                                        <b-td class="sidenav" colspan="4">
                                                            {{
                                                                `${actionMain.regionCode} Summary (Primary Constraints
                                                            and
                                                            based on constraint status)`
                                                            }}
                                                        </b-td>
                                                        <b-td>{{ rowData.totalPrimaryMachineCount }}</b-td>
                                                        <b-td>{{ rowData.totalYtdPrimaryCount }}</b-td>
                                                        <b-td>{{ rowData.totalYtdPrimaryPercentage }}</b-td>
                                                        <b-td>{{ rowData.totalYtdDec3Re2PrimaryCount }}</b-td>
                                                        <b-td>{{ rowData.totalYtdDec3Re2PrimaryCountPercentage }}</b-td>
                                                        <b-td>{{ rowData.prevDecReRplCountPrimary }}</b-td>
                                                        <b-td>{{ rowData.prevDecReRplCountPrimaryPercentage }}</b-td>
                                                        <b-td>{{ rowData.preYTDCompletedDecReRplPrimary }}</b-td>
                                                        <b-td>{{
                                                            rowData.preYTDCompletedDecReRplPrimaryPercentage
                                                        }}</b-td>
                                                        <b-td>{{ rowData.avgPrimarySR }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryAR }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryPR }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryQR }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryOee1 }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryOee2 }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryOee2Baseline }}</b-td>
                                                        <b-td>{{ rowData.avgPrimaryOee2Goal }}</b-td>
                                                    </b-tr>

                                                    <b-tr>
                                                        <b-td class="sidenav" colspan="4">
                                                            {{
                                                                `${actionMain.regionCode} Summary (ALL Constraints and
                                                            based
                                                            on constraint status)`
                                                            }}
                                                        </b-td>
                                                        <b-td>{{ rowData.totalMachinesCount }}</b-td>
                                                        <b-td>{{ rowData.totalYtdCount }}</b-td>
                                                        <b-td>{{ rowData.totalYtdCountPercentage }}</b-td>
                                                        <b-td>{{ rowData.totalYtdDec3Re2Count }}</b-td>
                                                        <b-td>{{ rowData.totalYtdDec3Re2CountPercentage }}</b-td>
                                                        <b-td>{{ rowData.prevDecReRplCount }}</b-td>
                                                        <b-td>{{ rowData.prevDecReRplCountPercentage }}</b-td>
                                                        <b-td>{{ rowData.preYTDCompletedDecReRplCount }}</b-td>
                                                        <b-td>{{
                                                            rowData.preYTDCompletedDecReRplCountPercentage
                                                        }}</b-td>
                                                        <b-td>{{ rowData.avgSR }}</b-td>
                                                        <b-td>{{ rowData.avgAR }}</b-td>
                                                        <b-td>{{ rowData.avgPR }}</b-td>
                                                        <b-td>{{ rowData.avgQR }}</b-td>
                                                        <b-td>{{ rowData.avgOee1 }}</b-td>
                                                        <b-td>{{ rowData.avgOee2 }}</b-td>
                                                        <b-td>{{ rowData.avgOee2Baseline }}</b-td>
                                                        <b-td>{{ rowData.avgOee2Goal }}</b-td>
                                                    </b-tr>
                                                </b-tfoot>
                                            </b-table-simple>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>

                        <div class="add-top-border">
                            <label class="summary-heading"> Global Summary for PRIMARY and ALL Constraints based on
                                Constraint Status </label>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                            <div class="oee-regional-count">
                                <b-table-simple responsive outlined id="oee-regional-count"
                                    class="oee-regional-count-table global-summary-table scroll gn-table-b">
                                    <b-thead head-variant="light" class="sticky">
                                        <b-tr>
                                            <b-th v-for="header in globalMachineDetailHeader"
                                                :rowspan="header.children.length ? 1 : 2"
                                                :colspan="header.children.length ? 2 : 1" :key="header.key"
                                                style="width: 6rem" class="first-tr-th">
                                                {{ header.label }}
                                            </b-th>
                                        </b-tr>
                                        <b-tr style="border-bottom: 2px solid #dddddd !important">
                                            <b-th v-for="(header, i) in allColsHeader" :key="header.key + i"
                                                class="second-tr-th">
                                                {{ header.label }}
                                            </b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tfoot v-if="showglobalSummary" style="color: #234372; background: #f0f7ff">
                                        <b-tr>
                                            <b-td class="sidenav"> Global Summary (Primary Constraints and based on
                                                constraint status) </b-td>
                                            <b-td>{{ globalSummary.globalPrimaryMachineCount }}</b-td>
                                            <b-td>{{ globalSummary.globalPrimaryYtdCount }}</b-td>
                                            <b-td>{{ globalSummary.globalPrimaryYtdPercent }}</b-td>
                                            <b-td>{{ globalSummary.globalDec3Re2PrimaryCount }}</b-td>
                                            <b-td>{{ globalSummary.globalDec3Re2PrimaryPercent }}</b-td>
                                            <b-td>{{ globalSummary.globalPreviousYtdPrimaryCount }}</b-td>
                                            <b-td>{{ globalSummary.globalPreviousYtdPrimaryPercent }}</b-td>
                                            <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplPrimary }}</b-td>
                                            <b-td>{{
                                                globalSummary.globalPreYTDCompletedDecReRplPrimaryPercentage
                                            }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimarySR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryAR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryPR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryQR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryOee1 }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryOee2 }}</b-td>

                                            <b-td>{{ globalSummary.globalAvgPrimaryOee2Baseline }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPrimaryOee2Goal }}</b-td>
                                        </b-tr>

                                        <b-tr>
                                            <b-td class="sidenav"> Global Summary (ALL Constraints and based on
                                                constraint status) </b-td>
                                            <b-td>{{ globalSummary.globalMachineCount }}</b-td>
                                            <b-td>{{ globalSummary.globalYtdCount }}</b-td>
                                            <b-td>{{ globalSummary.globalYtdPercent }}</b-td>
                                            <b-td>{{ globalSummary.globalDec3Re2Count }}</b-td>
                                            <b-td>{{ globalSummary.globalDec3Re2Percent }}</b-td>
                                            <b-td>{{ globalSummary.globalPreviousYtdCount }}</b-td>
                                            <b-td>{{ globalSummary.globalPreviousYtdPercent }}</b-td>
                                            <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplCount }}</b-td>
                                            <b-td>{{
                                                globalSummary.globalPreYTDCompletedDecReRplCountPercentage
                                            }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgSR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgAR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgPR }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgQR }}</b-td>
                                            <b-td>{{ globalSummary.globlalAvgOee1 }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgOee2 }}</b-td>

                                            <b-td>{{ globalSummary.globalAvgOee2Baseline }}</b-td>
                                            <b-td>{{ globalSummary.globalAvgOee2Goal }}</b-td>
                                        </b-tr>
                                    </b-tfoot>
                                    <b-tfoot v-else>
                                        <tr>
                                            <td class="text-center" style="height: 23rem" rowspan="6"
                                                :colspan="allColsHeader.length + machineDetailHeader.length">
                                                <img alt="no data" src="@/assets/No_Data.svg" />
                                                <div class="ed-date">No Data Found</div>
                                            </td>
                                        </tr>
                                    </b-tfoot>
                                </b-table-simple>
                            </div>
                        </div>
                    </div>
                </div>
            </b-table-simple>
            <!-- Excel Format -->

            <div id="target-approval-accordion">
                <div class="accordion" style="border-top: 0.5px solid #eae6e6" role="tablist" v-if="regionWiseList.length">
                    <div v-for="(actionMain, actionMainIndex) in regionWiseList" :key="(actionMain, actionMainIndex)"
                        class="add-top-border">
                        <div :id="actionMain.regionId">
                            <div class="level-status target-card-lable principle-name-label"
                                @click="checkToggle(collapsed_values[actionMain.regionCode], actionMain.regionCode)">
                                <b-button :aria-controls="actionMain.regionCode" class="img-button"
                                    :aria-expanded="collapsed_values[actionMain.regionCode] ? 'true' : 'false'">
                                    <img alt="down" v-if="!collapsed_values[actionMain.regionCode]"
                                        src="@/assets/accordian_down_arrow.svg" />
                                    <img alt="up" v-else src="@/assets/accordian_up_arrow.svg" />
                                </b-button>
                                {{ actionMain.regionName }}
                            </div>
                            <b-collapse v-bind:id="actionMain.regionCode" v-model="collapsed_values[actionMain.regionCode]">
                                <div style="display: flex; flex-wrap: wrap">
                                    <div class="oee-regional-count">
                                        <b-table-simple responsive outlined id="oee-regional-count"
                                            class="oee-regional-count-table scroll gn-table-b">
                                            <b-thead head-variant="light" class="sticky">
                                                <b-tr>
                                                    <b-th v-for="header in machineDetailHeader"
                                                        :rowspan="header.children.length ? 1 : 2"
                                                        :colspan="header.children.length ? 2 : 1" :key="header.key"
                                                        style="width: 6rem" class="first-tr-th">
                                                        {{ header.label }}
                                                    </b-th>
                                                </b-tr>
                                                <b-tr style="border-bottom: 2px solid #dddddd !important">
                                                    <b-th v-for="(header, i) in allColsHeader" :key="header.key + i"
                                                        class="second-tr-th">
                                                        {{ header.label }}
                                                    </b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody v-if="actionMain.oeeDetail && actionMain.oeeDetail.length !== 0">
                                                <b-tr v-for="(rowData, index) in actionMain.oeeDetail" :key="index">
                                                    <b-td v-for="(innerChild, key) in rowData" :key="key + index"
                                                        class="oee-regional-count-label">
                                                        {{ innerChild }}
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                            <b-tbody v-if="actionMain.oeeDetail && actionMain.oeeDetail.length === 0">
                                                <tr>
                                                    <td class="text-center" style="height: 23rem" rowspan="6"
                                                        :colspan="allColsHeader.length + machineDetailHeader.length">
                                                        <img alt="no data" src="@/assets/No_Data.svg" />
                                                        <div class="ed-date">No Data Found</div>
                                                    </td>
                                                </tr>
                                            </b-tbody>
                                            <b-tfoot style="color: #234372; background: #f0f7ff"
                                                v-for="(rowData, index) in actionMain.summaryDetail" :key="index">
                                                <b-tr>
                                                    <b-td class="sidenav" colspan="4">
                                                        {{
                                                            `${actionMain.regionCode} Summary (Primary Constraints
                                                        and
                                                        based on constraint status)`
                                                        }}
                                                    </b-td>
                                                    <b-td>{{ rowData.totalPrimaryMachineCount }}</b-td>
                                                    <b-td>{{ rowData.totalYtdPrimaryCount }}</b-td>
                                                    <b-td>{{ rowData.totalYtdPrimaryPercentage }}</b-td>
                                                    <b-td>{{ rowData.totalYtdDec3Re2PrimaryCount }}</b-td>
                                                    <b-td>{{ rowData.totalYtdDec3Re2PrimaryCountPercentage }}</b-td>
                                                    <b-td>{{ rowData.prevDecReRplCountPrimary }}</b-td>
                                                    <b-td>{{ rowData.prevDecReRplCountPrimaryPercentage }}</b-td>
                                                    <b-td>{{ rowData.preYTDCompletedDecReRplPrimary }}</b-td>
                                                    <b-td>{{ rowData.preYTDCompletedDecReRplPrimaryPercentage }}</b-td>
                                                    <b-td>{{ rowData.avgPrimarySR }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryAR }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryPR }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryQR }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryOee1 }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryOee2 }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryOee2Baseline }}</b-td>
                                                    <b-td>{{ rowData.avgPrimaryOee2Goal }}</b-td>
                                                </b-tr>

                                                <b-tr>
                                                    <b-td class="sidenav" colspan="4">
                                                        {{
                                                            `${actionMain.regionCode} Summary (ALL Constraints and
                                                        based
                                                        on constraint status)`
                                                        }}
                                                    </b-td>
                                                    <b-td>{{ rowData.totalMachinesCount }}</b-td>
                                                    <b-td>{{ rowData.totalYtdCount }}</b-td>
                                                    <b-td>{{ rowData.totalYtdCountPercentage }}</b-td>
                                                    <b-td>{{ rowData.totalYtdDec3Re2Count }}</b-td>
                                                    <b-td>{{ rowData.totalYtdDec3Re2CountPercentage }}</b-td>
                                                    <b-td>{{ rowData.prevDecReRplCount }}</b-td>
                                                    <b-td>{{ rowData.prevDecReRplCountPercentage }}</b-td>
                                                    <b-td>{{ rowData.preYTDCompletedDecReRplCount }}</b-td>
                                                    <b-td>{{ rowData.preYTDCompletedDecReRplCountPercentage }}</b-td>
                                                    <b-td>{{ rowData.avgSR }}</b-td>
                                                    <b-td>{{ rowData.avgAR }}</b-td>
                                                    <b-td>{{ rowData.avgPR }}</b-td>
                                                    <b-td>{{ rowData.avgQR }}</b-td>
                                                    <b-td>{{ rowData.avgOee1 }}</b-td>
                                                    <b-td>{{ rowData.avgOee2 }}</b-td>
                                                    <b-td>{{ rowData.avgOee2Baseline }}</b-td>
                                                    <b-td>{{ rowData.avgOee2Goal }}</b-td>
                                                </b-tr>
                                            </b-tfoot>
                                        </b-table-simple>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>

                    <div class="add-top-border">
                        <label class="summary-heading"> Global Summary for PRIMARY and ALL Constraints based on
                            Constraint Status </label>
                    </div>
                    <div style="display: flex; flex-wrap: wrap">
                        <div class="oee-regional-count">
                            <b-table-simple responsive outlined id="oee-regional-count"
                                class="oee-regional-count-table global-summary-table scroll gn-table-b">
                                <b-thead head-variant="light" class="sticky">
                                    <b-tr>
                                        <b-th v-for="header in globalMachineDetailHeader"
                                            :rowspan="header.children.length ? 1 : 2"
                                            :colspan="header.children.length ? 2 : 1" :key="header.key" style="width: 6rem"
                                            class="first-tr-th">
                                            {{ header.label }}
                                        </b-th>
                                    </b-tr>
                                    <b-tr style="border-bottom: 2px solid #dddddd !important">
                                        <b-th v-for="(header, i) in allColsHeader" :key="header.key + i"
                                            class="second-tr-th">
                                            {{ header.label }}
                                        </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tfoot v-if="showglobalSummary" style="color: #234372; background: #f0f7ff">
                                    <b-tr>
                                        <b-td class="sidenav"> Global Summary (Primary Constraints and based on
                                            constraint status) </b-td>
                                        <b-td>{{ globalSummary.globalPrimaryMachineCount }}</b-td>
                                        <b-td>{{ globalSummary.globalPrimaryYtdCount }}</b-td>
                                        <b-td>{{ globalSummary.globalPrimaryYtdPercent }}</b-td>
                                        <b-td>{{ globalSummary.globalDec3Re2PrimaryCount }}</b-td>
                                        <b-td>{{ globalSummary.globalDec3Re2PrimaryPercent }}</b-td>
                                        <b-td>{{ globalSummary.globalPreviousYtdPrimaryCount }}</b-td>
                                        <b-td>{{ globalSummary.globalPreviousYtdPrimaryPercent }}</b-td>
                                        <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplPrimary }}</b-td>
                                        <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplPrimaryPercentage }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimarySR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryAR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryPR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryQR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryOee1 }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryOee2 }}</b-td>

                                        <b-td>{{ globalSummary.globalAvgPrimaryOee2Baseline }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPrimaryOee2Goal }}</b-td>
                                    </b-tr>

                                    <b-tr>
                                        <b-td class="sidenav"> Global Summary (ALL Constraints and based on constraint
                                            status) </b-td>
                                        <b-td>{{ globalSummary.globalMachineCount }}</b-td>
                                        <b-td>{{ globalSummary.globalYtdCount }}</b-td>
                                        <b-td>{{ globalSummary.globalYtdPercent }}</b-td>
                                        <b-td>{{ globalSummary.globalDec3Re2Count }}</b-td>
                                        <b-td>{{ globalSummary.globalDec3Re2Percent }}</b-td>
                                        <b-td>{{ globalSummary.globalPreviousYtdCount }}</b-td>
                                        <b-td>{{ globalSummary.globalPreviousYtdPercent }}</b-td>
                                        <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplCount }}</b-td>
                                        <b-td>{{ globalSummary.globalPreYTDCompletedDecReRplCountPercentage }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgSR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgAR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgPR }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgQR }}</b-td>
                                        <b-td>{{ globalSummary.globlalAvgOee1 }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgOee2 }}</b-td>

                                        <b-td>{{ globalSummary.globalAvgOee2Baseline }}</b-td>
                                        <b-td>{{ globalSummary.globalAvgOee2Goal }}</b-td>
                                    </b-tr>
                                </b-tfoot>
                                <b-tfoot v-else>
                                    <tr>
                                        <td class="text-center" style="height: 23rem" rowspan="6"
                                            :colspan="allColsHeader.length + machineDetailHeader.length">
                                            <img alt="no data" src="@/assets/No_Data.svg" />
                                            <div class="ed-date">No Data Found</div>
                                        </td>
                                    </tr>
                                </b-tfoot>
                            </b-table-simple>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import Roles from "@/common/constants/roles";
import moment from "moment";
import MessagePopup from "@/components/common/MessagePopup.vue";
import XLSX from "xlsx";

export default {
    name: "ActionTrackerPillarWise",
    data() {
        return {
            alertMsg: "",
            showglobalSummary: true,
            showPopup: false,
            showLoader: true,
            editingMode: false,
            headerMessage: "",
            regionWiseList: [],
            rawData: [],
            willFreezeFor: moment(new Date()).format("MMMM"),
            willFreezeOn: moment(new Date(new Date())).format("MM/DD/YYYY"),
            monthsList: [
                { monthId: 1, monthName: "Jan" },
                { monthId: 2, monthName: "Feb" },
                { monthId: 3, monthName: "Mar" },
                { monthId: 4, monthName: "Apr" },
                { monthId: 5, monthName: "May" },
                { monthId: 6, monthName: "Jun" },
                { monthId: 7, monthName: "Jul" },
                { monthId: 8, monthName: "Aug" },
                { monthId: 9, monthName: "Sep" },
                { monthId: 10, monthName: "Oct" },
                { monthId: 11, monthName: "Nov" },
                { monthId: 12, monthName: "Dec" }
            ],
            plantList: [],
            committedYears: [],
            plantId: 1,
            selectedMonth: Number(new Date().getMonth()) + 1,
            committedYr: new Date().getFullYear(),
            ribbonConstraint: {},
            collapsed_values: {},
            machineDetailHeader: [
                {
                    key: "plantName",
                    label: "PLANT NAME",
                    children: [],
                    sortable: false
                },
                {
                    key: "constType",
                    label: "CONSTRAINT TYPE",
                    children: [],
                    sortable: false
                },
                {
                    key: "constReplicate",
                    label: "CONSTRAINTS TO REPLICATE",
                    children: [],
                    sortable: false
                },
                {
                    key: "prevConstraint",
                    label: "PREVIOUS CONSTRAINT",
                    children: [],
                    sortable: false
                },
                {
                    key: "totalConstraintMachines",
                    label: "TOTAL CONSTRAINT MACHINES",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraints",
                    label: "FOR ALL CONSTRAINTS",
                    children: [
                        {
                            key: "forAllConstraintsChild1",
                            label: "Total YTD RPL Count"
                        },
                        {
                            key: "forAllConstraintsChild2",
                            label: "Total RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraintsPrev",
                    label: `FOR ALL CONSTRAINTS (${this.committedYr})`,
                    children: [
                        {
                            key: "forAllConstraintsPrevChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "forAllConstraintsPrevChild2",
                            label: "DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forPreviousConstraints",
                    label: "FOR PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "forPreviousConstraintsChild1",
                            label: "Total YTD DEC3/RE5 RPL Count"
                        },
                        {
                            key: "forPreviousConstraintsChild2",
                            label: "Total RPL DEC3/RE5 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "previousConstraints",
                    label: "PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "previousConstraintsChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "previousConstraintsChild2",
                            label: "YTD DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeSR",
                    label: "OEE% Schedule Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeAR",
                    label: "OEE% Availability Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeePR",
                    label: "OEE% Performance Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeQR",
                    label: "OEE% Quality Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee1Percent",
                    label: "OEE1%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Percent",
                    label: "OEE2%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Baseline",
                    label: "OEE2% Baseline",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeGoal",
                    label: "OEE2% Goal",
                    children: [],
                    sortable: false,
                    variant: "success"
                }
            ],
            globalMachineDetailHeader: [
                {
                    key: "firstLabel",
                    label: "",
                    children: [],
                    sortable: false
                },
                {
                    key: "totalConstraintMachines",
                    label: "TOTAL CONSTRAINT MACHINES",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraints",
                    label: "FOR ALL CONSTRAINTS",
                    children: [
                        {
                            key: "forAllConstraintsChild1",
                            label: "Total YTD RPL Count"
                        },
                        {
                            key: "forAllConstraintsChild2",
                            label: "Total RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraintsPrev",
                    label: `FOR ALL CONSTRAINTS (${this.committedYr})`,
                    children: [
                        {
                            key: "forAllConstraintsPrevChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "forAllConstraintsPrevChild2",
                            label: "DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forPreviousConstraints",
                    label: "FOR PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "forPreviousConstraintsChild1",
                            label: "Total YTD DEC3/RE5 RPL Count"
                        },
                        {
                            key: "forPreviousConstraintsChild2",
                            label: "Total RPL DEC3/RE5 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "previousConstraints",
                    label: "PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "previousConstraintsChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "previousConstraintsChild2",
                            label: "YTD DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeSR",
                    label: "OEE% Schedule Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeAR",
                    label: "OEE% Availability Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeePR",
                    label: "OEE% Performance Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeQR",
                    label: "OEE% Quality Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee1Percent",
                    label: "OEE1%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Percent",
                    label: "OEE2%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Baseline",
                    label: "OEE2% Baseline",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeGoal",
                    label: "OEE Goal",
                    children: [],
                    sortable: false,
                    variant: "success"
                }
            ],
            allColsHeader: [],
            oeeDetail: [],
            summaryDetail: [],
            globalSummary: [],
            machineDetailX: [],
            roles: Roles,
            userPlantId: 0,
            emeaObj: [],
            naObj: [],
            apObj: [],
            laObj: []
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 7
        });
        this.$store.dispatch("breadcrumb", {
            title: "OEE Regional Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "OEE Regional Report", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        let day = 7;
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        let freezeon = new Date(year, month, day);
        // (year: number, month: number, date?: number | undefined, hours?: number | undefined, minutes?: number | undefined, seconds?: number | undefined, ms?: number | undefined)
        console.log(day, month, year, freezeon);
        this.willFreezeOn = moment(freezeon).format("DD-MMM-YYYY");
        this.willFreezeFor = new Date().getDate() <= 7 ? moment(new Date(year, new Date().getMonth() - 1, day)).format("MMMM") : moment(new Date()).format("MMMM");
        // Data entries for {{ willFreezeFor }} will freeze on {{ willFreezeOn }}
        this.getCommittedYear();
        this.placeChildElements();
        this.getRegions();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"])
    },
    methods: {
        getRegions() {
            ApiCalls.httpCallDetails(Api.MACHINEREGIONLIST, "get").then((data) => {
                this.regionWiseList = data.data.map((item) => ({ ...item, oeeDetail: [], summaryDetail: [] }));
                console.log("regionList", this.regionWiseList);
                this.machineDetailHeader[6].label = `FOR ALL CONSTRAINTS (${this.committedYr})`
                this.globalMachineDetailHeader[3].label = `FOR ALL CONSTRAINTS (${this.committedYr})`
                this.filterHandler();
            });
        },
        checkToggle(collapsedValue, regionId) {
            console.log(collapsedValue, regionId);
            this.collapsed_values[regionId] = !collapsedValue;
        },
        placeChildElements() {
            let elements = [];
            this.machineDetailHeader.forEach((header) => {
                let temp = [];
                if (header.children.length)
                    header.children.forEach((child) => {
                        temp.push({
                            label: child.label,
                            key: child.key
                        });
                    });

                elements = [...elements, ...temp];
            });
            console.log(this.oeeDetail);
            this.allColsHeader = elements;
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.GETOEEDASHBOARDYEAR, "get").then((data) => {
                this.committedYears = data.data.length ? data.data : [new Date().getFullYear()];
            });
        },
        filterHandler() {
            this.showLoader = true;
            const url = Api.GETOEEREGIONALCOUNTREPORT + "/" + this.committedYr + "/" + this.selectedMonth;
            console.log("Regional URL", url);
            ApiCalls.httpCallDetails(url, "get").then((data) => {
                let res = data;
                this.oeeDetail = [];
                this.summaryDetail = [];
                console.log("Response", res);
                this.showLoader = false;

                if (res.success) {
                    this.showglobalSummary = true;
                    Object.keys(res.data).forEach((key, index) => {
                        this.globalSummary = {};

                        if (key !== "regionalData") this.globalSummary[key] = res.data[key];

                        this.globalSummary = {
                            globalPrimaryMachineCount: res.data.globalPrimaryMachineCount,
                            globalMachineCount: res.data.globalMachineCount,
                            globalPrimaryYtdCount: res.data.globalPrimaryYtdCount ? Math.round(res.data.globalPrimaryYtdCount * 100) / 100 : 0,
                            globalYtdCount: res.data.globalYtdCount ? Math.round(res.data.globalYtdCount * 100) / 100 : 0,
                            globalPrimaryYtdPercent: res.data.globalPrimaryYtdPercent ? Math.round(res.data.globalPrimaryYtdPercent * 100) / 100 : 0,
                            globalYtdPercent: res.data.globalYtdPercent ? Math.round(res.data.globalYtdPercent * 100) / 100 : 0,
                            globalDec3Re2PrimaryCount: res.data.globalDec3Re2PrimaryCount,
                            globalDec3Re2Count: res.data.globalDec3Re2Count,
                            globalDec3Re2PrimaryPercent: res.data.globalDec3Re2PrimaryPercent ? Math.round(res.data.globalDec3Re2PrimaryPercent * 100) / 100 : 0,
                            globalDec3Re2Percent: res.data.globalDec3Re2Percent ? Math.round(res.data.globalDec3Re2Percent * 100) / 100 : 0,
                            globalPreviousYtdPrimaryCount: res.data.globalPreviousYtdPrimaryCount,
                            globalPreviousYtdCount: res.data.globalPreviousYtdCount,
                            globalPreviousYtdPrimaryPercent: res.data.globalPreviousYtdPrimaryPercent ? Math.round(res.data.globalPreviousYtdPrimaryPercent * 100) / 100 : 0,
                            globalPreviousYtdPercent: res.data.globalPreviousYtdPercent ? Math.round(res.data.globalPreviousYtdPercent * 100) / 100 : 0,
                            globalPreYTDCompletedDecReRplPrimary: res.data.globalPreYTDCompletedDecReRplPrimary,
                            globalPreYTDCompletedDecReRplCount: res.data.globalPreYTDCompletedDecReRplCount,
                            globalPreYTDCompletedDecReRplPrimaryPercentage: res.data.globalPreYTDCompletedDecReRplPrimaryPercentage
                                ? Math.round(res.data.globalPreYTDCompletedDecReRplPrimaryPercentage * 100) / 100
                                : 0,
                            globalPreYTDCompletedDecReRplCountPercentage: res.data.globalPreYTDCompletedDecReRplCountPercentage
                                ? Math.round(res.data.globalPreYTDCompletedDecReRplCountPercentage * 100) / 100
                                : 0,
                            globalAvgPrimarySR: res.data.globalAvgPrimarySR ? Math.round(res.data.globalAvgPrimarySR * 100) / 100 : 0,
                            globalAvgSR: res.data.globalAvgSR ? Math.round(res.data.globalAvgSR * 100) / 100 : 0,
                            globalAvgPrimaryAR: res.data.globalAvgPrimaryAR ? Math.round(res.data.globalAvgPrimaryAR * 100) / 100 : 0,
                            globalAvgAR: res.data.globalAvgAR ? Math.round(res.data.globalAvgAR * 100) / 100 : 0,
                            globalAvgPrimaryPR: res.data.globalAvgPrimaryPR ? Math.round(res.data.globalAvgPrimaryPR * 100) / 100 : 0,
                            globalAvgPR: res.data.globalAvgPR ? Math.round(res.data.globalAvgPR * 100) / 100 : 0,
                            globalAvgPrimaryQR: res.data.globalAvgPrimaryQR ? Math.round(res.data.globalAvgPrimaryQR * 100) / 100 : 0,
                            globalAvgQR: res.data.globalAvgQR ? Math.round(res.data.globalAvgQR * 100) / 100 : 0,
                            globalAvgPrimaryOee1: res.data.globalAvgPrimaryOee1 ? Math.round(res.data.globalAvgPrimaryOee1 * 100) / 100 : 0,
                            globlalAvgOee1: res.data.globlalAvgOee1 ? Math.round(res.data.globlalAvgOee1 * 100) / 100 : 0,
                            globalAvgPrimaryOee2: res.data.globalAvgPrimaryOee2 ? Math.round(res.data.globalAvgPrimaryOee2 * 100) / 100 : 0,
                            globalAvgOee2: res.data.globalAvgOee2 ? Math.round(res.data.globalAvgOee2 * 100) / 100 : 0,
                            globalAvgPrimaryOee2Baseline: res.data.globalAvgPrimaryOee2Baseline ? Math.round(res.data.globalAvgPrimaryOee2Baseline * 100) / 100 : 0,
                            globalAvgPrimaryOee2Goal: res.data.globalAvgPrimaryOee2Goal ? Math.round(res.data.globalAvgPrimaryOee2Goal * 100) / 100 : 0,
                            globalAvgOee2Baseline: res.data.globalAvgOee2Baseline ? Math.round(res.data.globalAvgOee2Baseline * 100) / 100 : 0,
                            globalAvgOee2Goal: res.data.globalAvgOee2Goal ? Math.round(res.data.globalAvgOee2Goal * 100) / 100 : 0
                        };
                    });

                    res.data.regionalData.forEach((resp) => {
                        if (resp.oeeRegionTable.length != 0) {
                            this.regionWiseList.forEach((region) => {
                                resp.oeeRegionTable.sort((a, b) => {
                                    if (a.plantName < b.plantName) return -1;
                                    else return a.plantName === b.plantName ? 0 : 1;
                                });
                                resp.oeeRegionTable.forEach((oeDt) => {
                                    oeDt.oeeData.sort((a, b) => {
                                        if (a.constraintType < b.constraintType) return -1;
                                        else return a.constraintType === b.constraintType ? 0 : 1;
                                    });
                                    oeDt.oeeData.forEach((od) => {
                                        if (od.regionName == region.regionName) {
                                            region["oeeDetail"].push({
                                                plantName: od.plantName,
                                                constType: od.constraintType,
                                                constraintGroupName: od.constraintGroupName,
                                                prevConstraint: od.isPreviousConstraint ? "Y" : "N",
                                                totalConstraintMachines: od.totalMachines ? od.totalMachines : 0,
                                                forAllConstraintsChild1: od.ytdrplCount ? od.ytdrplCount : 0,
                                                forAllConstraintsChild2: od.ytdrplPercentage ? Math.round(od.ytdrplPercentage * 100) / 100 : 0,
                                                forAllConstraintsPrevChild1: od.decrerplCount ? Math.round(od.decrerplCount * 100) / 100 : 0,
                                                forAllConstraintsPrevChild2: od.decrerplPercent ? Math.round(od.decrerplPercent * 100) / 100 : 0,
                                                forPreviousConstraintsChild1: od.prevDECRERPLCount ? Math.round(od.prevDECRERPLCount * 100) / 100 : 0,
                                                forPreviousConstraintsChild2: od.prevDECRERPLPercent ? Math.round(od.prevDECRERPLPercent * 100) / 100 : 0,
                                                previousConstraintsChild1: od.preYTDCompletedDECRERPLCount ? Math.round(od.preYTDCompletedDECRERPLCount * 100) / 100 : 0,
                                                previousConstraintsChild2: od.preYTDCompletedDECRERPLPercent ? Math.round(od.preYTDCompletedDECRERPLPercent * 100) / 100 : 0,
                                                oeeSR: od.scheduleRate ? Math.round(od.scheduleRate * 100) / 100 : 0,
                                                oeeAR: od.availabilityRate ? Math.round(od.availabilityRate * 100) / 100 : 0,
                                                oeePR: od.performanceRate ? Math.round(od.performanceRate * 100) / 100 : 0,
                                                oeeQR: od.qualityRate ? Math.round(od.qualityRate * 100) / 100 : 0,
                                                oee1Percent: od.oeE1 ? Math.round(od.oeE1 * 100) / 100 : 0,
                                                oee2Percent: od.oeE2 ? Math.round(od.oeE2 * 100) / 100 : 0,
                                                oee2Baseline: od.baseline ? Math.round(od.baseline * 100) / 100 : 0,
                                                oeeGoal: od.goal ? Math.round(od.goal * 100) / 100 : 0
                                            });
                                        }
                                    });
                                });
                            });
                        }
                    });
                    res.data.regionalData.forEach((re) => {
                        if (re.oeeRegionTable.length != 0) {
                            this.regionWiseList.forEach((region) => {
                                if (re.regionName == region.regionName) {
                                    region.summaryDetail.push({
                                        // Primary
                                        totalPrimaryMachineCount: re.totalPrimaryMachineCount,
                                        totalYtdPrimaryCount: re.totalYtdPrimaryCount,
                                        totalYtdPrimaryPercentage: re.totalYtdPrimaryPercentage ? Math.round(re.totalYtdPrimaryPercentage * 100) / 100 : 0,
                                        totalYtdDec3Re2PrimaryCount: re.totalYtdDec3Re2PrimaryCount,
                                        totalYtdDec3Re2PrimaryCountPercentage: re.totalYtdDec3Re2PrimaryCountPercentage ? Math.round(re.totalYtdDec3Re2PrimaryCountPercentage * 100) / 100 : 0,
                                        prevDecReRplCountPrimary: re.prevDecReRplCountPrimary,
                                        prevDecReRplCountPrimaryPercentage: re.prevDecReRplCountPrimaryPercentage ? Math.round(re.prevDecReRplCountPrimaryPercentage * 100) / 100 : 0,
                                        preYTDCompletedDecReRplPrimary: re.preYTDCompletedDecReRplPrimary,
                                        preYTDCompletedDecReRplPrimaryPercentage: re.preYTDCompletedDecReRplPrimaryPercentage
                                            ? Math.round(re.preYTDCompletedDecReRplPrimaryPercentage * 100) / 100
                                            : 0,
                                        avgPrimarySR: re.avgPrimarySR ? Math.round(re.avgPrimarySR * 100) / 100 : 0,
                                        avgPrimaryAR: re.avgPrimaryAR ? Math.round(re.avgPrimaryAR * 100) / 100 : 0,
                                        avgPrimaryPR: re.avgPrimaryPR ? Math.round(re.avgPrimaryPR * 100) / 100 : 0,
                                        avgPrimaryQR: re.avgPrimaryQR ? Math.round(re.avgPrimaryQR * 100) / 100 : 0,
                                        avgPrimaryOee1: re.avgPrimaryOee1 ? Math.round(re.avgPrimaryOee1 * 100) / 100 : 0,
                                        avgPrimaryOee2: re.avgPrimaryOee2 ? Math.round(re.avgPrimaryOee2 * 100) / 100 : 0,
                                        avgPrimaryOee2Baseline: re.avgPrimaryOee2Baseline ? Math.round(re.avgPrimaryOee2Baseline * 100) / 100 : 0,
                                        avgPrimaryOee2Goal: re.avgPrimaryOee2Goal ? Math.round(re.avgPrimaryOee2Goal * 100) / 100 : 0,

                                        // Total
                                        totalMachinesCount: re.totalMachinesCount,
                                        totalYtdCount: re.totalYtdCount,
                                        totalYtdCountPercentage: re.totalYtdCountPercentage ? Math.round(re.totalYtdCountPercentage * 100) / 100 : 0,
                                        totalYtdDec3Re2Count: re.totalYtdDec3Re2Count,
                                        totalYtdDec3Re2CountPercentage: re.totalYtdDec3Re2CountPercentage ? Math.round(re.totalYtdDec3Re2CountPercentage * 100) / 100 : 0,
                                        prevDecReRplCount: re.prevDecReRplCount,
                                        prevDecReRplCountPercentage: re.prevDecReRplCountPercentage ? Math.round(re.prevDecReRplCountPercentage * 100) / 100 : 0,
                                        preYTDCompletedDecReRplCount: re.preYTDCompletedDecReRplCount,
                                        preYTDCompletedDecReRplCountPercentage: re.preYTDCompletedDecReRplCountPercentage ? Math.round(re.preYTDCompletedDecReRplCountPercentage * 100) / 100 : 0,
                                        avgSR: re.avgSR ? Math.round(re.avgSR * 100) / 100 : 0,
                                        avgAR: re.avgAR ? Math.round(re.avgAR * 100) / 100 : 0,
                                        avgPR: re.avgPR ? Math.round(re.avgPR * 100) / 100 : 0,
                                        avgQR: re.avgQR ? Math.round(re.avgQR * 100) / 100 : 0,
                                        avgOee1: re.avgOee1 ? Math.round(re.avgOee1 * 100) / 100 : 0,
                                        avgOee2: re.avgOee2 ? Math.round(re.avgOee2 * 100) / 100 : 0,
                                        avgOee2Baseline: re.avgOee2Baseline ? Math.round(re.avgOee2Baseline * 100) / 100 : 0,
                                        avgOee2Goal: re.avgOee2Goal ? Math.round(re.avgOee2Goal * 100) / 100 : 0
                                    });
                                }
                            });
                        }
                    });
                    console.log("regionWiseList", this.regionWiseList[0].summaryDetail);
                } else {
                    this.showglobalSummary = false;
                }
            });
        },
        exportToExcel() {
            let wb = XLSX.utils.table_to_book(document.getElementById("oeeregionreport"));
            XLSX.writeFile(wb, "OEE Regional Report.xlsx");
        }
    }
};
</script>

<style scoped>
.summary-heading {
    color: #234372;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
    padding: 1rem 2rem;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
}

.sidenav {
    position: sticky;
    left: 0;
}

.add-top-border {
    border-top: 1px solid #eae6e6;
    border-bottom: 0.5px solid #eae6e6;
}

.level-status {
    color: #454545;
}

.principle-name-label {
    margin: 1rem 0;
    vertical-align: middle;
    font-size: 1.33rem;
    font-family: "BarlowM", sans-serif;
    line-height: 1.6666666666666667rem;
}

.img-button {
    cursor: pointer;
    background-color: white;
    border-color: white;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

input.input-f.red-border,
input.input-f.red-border:hover,
input.input-f.red-border:active,
input.input-f.red-border:focus,
input.input-f.red-border:focus-visible,
input.input-f.red-border:focus-within {
    border-color: red !important;
}

.cml-by {
    width: 80%;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.333rem;
    color: #d35757;
    text-align: right;
}

.cml-by-update {
    width: 96%;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.333rem;
    color: #869ed8;
    text-align: right;
}

.cmp-by-txt {
    margin-left: 0.3rem;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.target-card-lable {
    margin-bottom: 0.5rem;
    padding: 0 1.25rem;
}

.top-ribbon {
    display: flex;
    justify-content: space-evenly;
    padding: 0 2rem;
    height: 7rem;
    background-color: #eff3ff;
    border: 1px solid #dee0e8;
    align-items: center;
}

.inner-top-ribbon {
    text-align: center;
}

.last-ud-by {
    font-size: 0.916rem;
    font-family: "BarlowR", sans-serif;
    color: #5a5a5a;
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 100%;
    max-width: 5rem;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

.no-fl-con {
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 11rem;
}

.no-fl-hd {
    font-size: 1.166rem !important;
    font-family: "BarlowB", sans-serif !important;
    margin-top: 1rem;
}

.no-fl-sub {
    font-size: 1.166rem !important;
    font-family: "BarlowR", sans-serif !important;
}

.no-fl-img {
    width: 7rem;
}

.oee-regional-count {
    /* margin-top: 2rem; */
    width: 100%;
}

.table-responsive {
    padding-bottom: 1rem;
}

#oee-regional-count thead .sticky .thead-light {
    z-index: 4 !important;
}

#oee-regional-count th.table-success {
    background-color: #f4f6fd;
}

#oee-regional-count th {
    background-color: #f7f9ff !important;
    font-weight: bold;
}

#oee-regional-count tr td,
#oee-regional-count tr th {
    border: 2px solid #efefef !important;
}

#oee-regional-count tr th.first-tr-th:first-child {
    position: sticky;
    left: 0.1px;
    background: #f7f9ff !important;
    box-shadow: inset -1px -1px 0px 0px #efefef;
}

/* 
#oee-regional-count tr th.first-tr-th:first-child {
    background: #f7f9ff !important;
} */

#oee-regional-count tr td:first-child {
    z-index: 1;
    position: sticky;
    left: 0.1px;
    background-color: white !important;
    box-shadow: inset -1px -1px 0px 0px #efefef;
}

.global-summary-table tr th.first-tr-th:first-child {
    padding: 0 17rem;
}

#oee-regional-count tr:nth-child(even) td:first-child {
    background-color: #fafafa !important;
}

#oee-regional-count tr th:last-child {
    padding: 0 2rem;
}

.oee-regional-count-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
    color: #313131 !important;
}

.oee-regional-count-table {
    max-height: 37rem !important;
}

.oee-regional-count-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.second-tr-th {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1rem;
    font-family: "BarlowSb", sans-serif;
    color: #313131 !important;
    padding: 0.5rem 2rem;
}

.oee-regional-count-table tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.oee-regional-count-table thead th,
.oee-regional-count-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

#oee-regional-count>.oee-regional-count-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* .oee-regional-count-table th {
    width: 15%;
} */

.oee-regional-count-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.oee-regional-count-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.oee-regional-count-table .table-light,
.oee-regional-count-table .table-light>td,
.oee-regional-count-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.oee-regional-count-table th {
    color: #313131 !important;
    font-weight: 100;
}

.oee-regional-count-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.oee-regional-count-table tr {
    height: 5rem;
}

.scroll {
    height: auto;
}

.scroll::-webkit-scrollbar {
    width: 9px;
    margin: 1rem 2rem;
    height: 8px !important;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
